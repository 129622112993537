@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

/*== Default ==*/

:root {
  --primary: #003b73;
  --secondary: #611829;
  --neutral: #dee4f0;
  --accent: #424a57;
}

html {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

body {
  font-family: "Roboto", sans-serif;
}

.Container {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 768px) {
  .Container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.button {
  @apply border border-secondary rounded font-medium bg-primary text-white transition ease-in-out duration-300 text-base tracking-wider hover:opacity-70;
  padding: 6px 13px;
}

.transparentButton {
  @apply border border-slate-400 hover:text-secondary hover:border-slate-800 rounded font-medium bg-transparent text-accent transition ease-in-out duration-500 text-base tracking-wider;
  padding: 6px 13px;
}

.button:disabled,
.button:disabled:hover,
.button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

/*== Auth section ===*/
.auth_page {
  @apply w-full h-screen flex items-center justify-center px-5 bg-neutral overflow-auto;
}

.auth_page button {
  @apply bg-primary text-white w-full rounded mt-5 py-2 uppercase tracking-wider font-medium transition ease-in-out duration-300 hover:opacity-70;
}

.form-group {
  @apply w-full;
}

.form-group label {
  @apply block pb-1;
}

.form-group input {
  @apply w-full border border-slate-300 pl-2 pr-10 py-2 rounded-md placeholder:text-sm text-sm focus:outline-none;
}

.formWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  padding: 10px 0;
}

/*== Sidebar menu ==*/
.sidebarWrap {
  background: var(--primary);
  color: #fff;
  height: 100vh;
  transition: all 0.5s;
  position: fixed;
  display: flex;
  justify-content: flex-end;
}

.sidebar_link {
  @apply flex items-center gap-x-7 text-base my-4;
}

.sidebar_link i {
  @apply text-xl font-medium;
}

.sidebar_link span::after {
  content: "";
  width: 0%;
  height: 2px;
  display: block;
  background-color: #fff;
  margin-top: 7px;
  transition: all 0.3s;
}

.sidebar_link:hover span::after,
.sidebar_link.active span::after {
  width: 100%;
  transition: all 0.3s;
}

.sidebar_link_mobile {
  @apply flex items-center gap-x-7 cursor-pointer text-base hover:text-secondary;
}

.sidebar_link_mobile.active {
  @apply text-secondary;
}

/*== Home page ==*/
.homeCard {
  @apply flex items-center gap-x-7 rounded-md text-gray-200 px-5 py-7;
}

/*=== Account page  ===*/
.form-control {
  @apply mb-4;
}

.form-control label {
  @apply block pb-1 text-sm text-accent;
}

.form-control input,
.form-control select {
  @apply w-full rounded p-2 focus:outline-none text-sm border;
}
.form-control textarea {
  @apply w-full rounded p-2 focus:outline-none text-sm border h-28 resize-none;
}

/*=== Media query ===*/
@media (max-width: 768px) {
  .sidebarWrap {
    display: none;
  }
}

@media (min-width: 769px) {
  .topbar {
    display: none;
  }
}

/*=== Properties ====*/

.propertyWrap .box {
  @apply border bg-gray-100 w-full relative;
}

.propertyWrap .box .top {
  position: relative;
  background-color: red;
}

.propertyWrap .box .top:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(43, 187, 175, 0.829);
  position: absolute;
  left: 0;
  top: 0;
  transition: 0.3s;
  opacity: 0;
}

.propertyWrap .box:hover .top:before {
  opacity: 1;
}

.propertyWrap .box .top:after {
  content: "View Property";
  color: white;
  font-size: 12px;
  padding: 10px;
  border: 1px solid white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: 0.3s;
  opacity: 0;
}

.propertyWrap .box:hover .top:after {
  opacity: 1;
}

.propertyWrap .box .top img {
  width: 100%;
  margin-bottom: -4px;
}

.propertyWrap .box .top span {
  position: absolute;
  right: 20px;
  bottom: 15px;
  color: white;
  font-size: 20px;
}

.propertyWrap .box .top span i {
  margin-left: 5px;
}

.propertyWrap .box .bottom {
  padding: 10px 15px;
  position: relative;
}

.propertyWrap .box .bottom > p {
  margin: 15px 0;
  font-size: 13px;
  line-height: 1.4;
  color: #777;
}

.propertyWrap .box .bottom .advants {
  display: flex;
}

.propertyWrap .box .bottom .advants > div > span {
  font-size: 12px;
}

.propertyWrap .box .bottom .advants > div > div {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.propertyWrap .box .bottom .advants > div > div i {
  color: #777;
}

.propertyWrap .box .bottom .advants > div > div > span {
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
}

.propertyWrap .box .bottom .advants > div > div > span > span {
  color: #777;
  font-weight: normal;
  margin-left: 5px;
}

/*=== General modal style ===*/
.CModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 15px 25px 25px 25px;
  border-radius: 10px;
  width: 90%;
  overflow-y: auto;
}

/*=== List Property ====*/
.general_table {
  @apply w-full table-auto border-collapse border border-slate-400 text-center overflow-x-auto;
}

.general_table th,
.general_table td {
  @apply border border-slate-300 p-2;
}

.general_table th {
  @apply text-sm;
}

.general_table td {
  @apply text-sm;
}

.form-control label {
  @apply block pl-1 font-medium text-base;
}

.errorMsg {
  @apply text-xs text-red-500 block pt-1 pl-1;
}

/*=== General scroll bar ===*/
.scrollBar::-webkit-scrollbar {
  width: 12px;
}

.scrollBar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px gray;
  border-radius: 10px;
}

.scrollBar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px gray;
}

.form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 90%;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
